<template>
    <slot />
</template>

<script>
export default {
    name: "empty"
}
</script>

<style scoped>

</style>
